import React, { useState } from 'react'
import { IoArrowBack } from "react-icons/io5";
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../Compunents/Loader/Loader';
import style from './style.module.css'
import { FaCamera } from "react-icons/fa";
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { IoIosArrowForward } from "react-icons/io";
import ProfileEdit from './ProfileEdit';
import { deleteImage, uploadFile } from '../../../Firebase/CloudStorage/UploadImages';
import { updateDocument } from '../../../Firebase/CloudFirestore/UpdateData';
import Snakbar from '../../../Compunents/Snackbar/Snakbar';
import { getDocumentData } from '../../../Firebase/CloudFirestore/GetData';
import { useMediaQuery } from '@mui/material';
import EducationDetails from './EducationDetails';
import ExperienceDetails from './ExperienceDetails';
import CertificatDetails from './CertificatDetails';
import { useSelector } from 'react-redux';
import { getPermissionAccess } from '../../../Utils/Utils';
import AccountDetails from './AccountDetails';

export default function ViewJobSeekerData() {
    let { id } = useParams()
    const navigate = useNavigate()
    const matches = useMediaQuery('(min-width:768px)');
    const selector = useSelector((e) => e?.permissionData?.permissions)

    const [imgUrl, setImgUrl] = useState('')
    const [loader, setLoader] = useState(true)
    const [activeTab, toggleTab] = useState('0')
    const [showData, setShowData] = useState({
        name: '',
        email: '',
        gender: ''
    })
    const [snackbar, showSnackbar] = useState({
        show: false,
        msg: "data added",
        type: "error",
    });




    async function changeProfileImg(e) {
        let file = e.target.files[0]
        setLoader(true)
        try {
            if (imgUrl !== '') {
                await deleteImage(imgUrl)
            }
            let res = await uploadFile(file)
            await updateDocument('users', id, { profileImage: res })
            showSnackbar({
                show: true,
                msg: "Profile image updated successfully",
                type: "success",
            })
        } catch (err) {
            console.log(err)
            showSnackbar({
                show: true,
                msg: "There are some problem please try again",
                type: "error",
            })
            return
        } finally {
            await getUserData()
            setLoader(false)
        }
    }


    async function getUserData() {
        setLoader(true)
        let response = {}
        try {
            let res = await getDocumentData('users', id)
            console.log(res)
            response = res
            setImgUrl(res?.profileImage)
            let pData = {
                name: `${res?.firstName} ${res?.lastName}`,
                email: res?.email,
                gender: res?.gender
            }
            setShowData(pData)

        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }
        return response
    }




    return (
        <>
            <Loader open={loader} />
            <Snakbar data={snackbar} setData={showSnackbar} />
            <button className='btn ps-0 d-flex align-items-center' onClick={() => navigate('/userManagement/jobSeekers')}><IoArrowBack className='me-1' /> Back</button>
            <div className='mt-4'>
                <div className='d-flex align-items-center'>
                    <div className='p-relative'>
                        <div className={`${style.profileContainer}`}>
                            <img src={imgUrl == '' ? showData?.gender == 'male' ? '/images/maleProfile.jpg' : '/images/femaleProfile.jpg' : imgUrl} className='w-100 h-100' />
                        </div>
                        {getPermissionAccess("Job Seeker", selector, false, 'Edit Job Seeker')?
                        <label className={style.camera} htmlFor='profileChange'>
                            <FaCamera className='text-white f-13' />
                        </label>:''}
                        <input type='file' hidden id='profileChange' onChange={changeProfileImg} />
                    </div>
                    <div className='d-flex flex-column ms-md-4 ms-sm-3 ms-3'>
                        <div className='fs-md-3 fs-sm-4 fs-5 fw-bold pt-3 text-secondary text-capitalize'>{showData?.name}</div>
                        <div className='text-gray ms-1'>{showData?.email}</div>
                    </div>
                </div>
            </div>
            <div className={`mt-4 ${style.tabContainer}`}>
                <div className={`${style.left}`}>
                    <Nav vertical={matches} className={`${!matches ? 'border-bottom flex flex-nowrap' : ''}`}>
                        <NavItem>
                            <NavLink
                                className={`text-secondary fw-bold ${activeTab == "0" ? !matches ? 'activeTab2' : 'activeTab text-white ' : ""} ${!matches ? 'px-2' : 'rounded ps-4 mt-2 py-2'} pointer`}
                                onClick={() => toggleTab("0")}>
                                <div className='d-flex justify-content-between align-items-center'><div>Profile</div> <IoIosArrowForward className='fw-600 d-md-block d-sm-none d-none' /></div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={`text-secondary fw-bold ${activeTab == "1" ? !matches ? 'activeTab2' : 'activeTab text-white ' : ""} ${!matches ? 'px-2' : 'rounded ps-4 mt-2 py-2'} pointer`}
                                onClick={() => toggleTab("1")}>
                                <div className='d-flex justify-content-between align-items-center'><div>Education</div> <IoIosArrowForward className='fw-600 d-md-block d-sm-none d-none' /></div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={`text-secondary fw-bold ${activeTab == "2" ? !matches ? 'activeTab2' : 'activeTab text-white ' : ""} ${!matches ? 'px-2' : 'rounded ps-4 mt-2 py-2'} pointer`}
                                onClick={() => toggleTab("2")}>
                                <div className='d-flex justify-content-between align-items-center'><div>Experience</div> <IoIosArrowForward className='fw-600 d-md-block d-sm-none d-none' /></div>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={`text-secondary fw-bold ${activeTab == "3" ? !matches ? 'activeTab2' : 'activeTab text-white ' : ""} ${!matches ? 'px-2' : 'rounded ps-4 mt-2 py-2'} pointer`}
                                onClick={() => toggleTab("3")}>
                                <div className='d-flex justify-content-between align-items-center'><div>Certifications</div> <IoIosArrowForward className='fw-600 d-md-block d-sm-none d-none' /></div>
                            </NavLink>
                        </NavItem>

                        <NavItem>
                            <NavLink
                                className={`text-secondary fw-bold ${activeTab == "4" ? !matches ? 'activeTab2' : 'activeTab text-white ' : ""} ${!matches ? 'px-2' : 'rounded ps-4 mt-2 py-2'} pointer`}
                                onClick={() => toggleTab("4")}>
                                <div className='d-flex justify-content-between align-items-center'><div>Account Details</div> <IoIosArrowForward className='fw-600 d-md-block d-sm-none d-none' /></div>
                            </NavLink>
                        </NavItem>
                    </Nav>

                </div>
                <div className={`${style.right}`}>
                    <TabContent className="px-md-3 px-sm-2 px-1 pt-md-4 pt-sm-4 pt-3" activeTab={activeTab}>
                        <TabPane tabId="0">
                            <ProfileEdit setLoader={setLoader} getUserData={getUserData} activeTab={activeTab} />
                        </TabPane>
                        <TabPane tabId="1">
                            <EducationDetails activeTab={activeTab} setLoader={setLoader} />
                        </TabPane>
                        <TabPane tabId="2">
                            <ExperienceDetails activeTab={activeTab} setLoader={setLoader} />
                        </TabPane>

                        <TabPane tabId="3">
                            <CertificatDetails activeTab={activeTab} setLoader={setLoader} />
                        </TabPane>
                        
                        <TabPane tabId="4">
                            <AccountDetails activeTab={activeTab} setLoader={setLoader} />
                        </TabPane>

                    </TabContent>
                </div>
            </div>
        </>
    )
}

