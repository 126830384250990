import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getDocumentData, getMatchingData } from '../../../Firebase/CloudFirestore/GetData'

export default function AccountDetails({ activeTab, setLoader }) {
    let { id } = useParams()
    const [isData,setIsData] = useState(true)
    const [data, setData] = useState({
        bankName: "",
        accountNumber: "",
        ifscCode: "",
        idNumber: "",
    });

    async function getAccountDetails() {
        setLoader(true)
        try {
            let res = await getDocumentData('userBankDetail', id)
            setData(res)
            if(res?.bankName !== undefined){
                setIsData(true)
            }else{
                setIsData(false)
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoader(false)
        }

    }
    useEffect(() => {
        if (activeTab === '4') {
            getAccountDetails()
        }
    }, [activeTab])

    return (
        <>
            <h4 className=''>Account Details</h4>
            {isData?<div className='row mt-4 '>
                <div className='col-md-6 col-sm-6 col-12 mt-2'>
                    <label className='form-label'>Bank name :</label>
                    <input className='form-control' type="text" disabled value={data?.bankName} name="BankName" />
                    <br />
                </div>

                <div className='col-md-6 col-sm-6 col-12 mt-2'>
                    <label className='form-label'>Account number :</label>
                    <input className='form-control' type="text" disabled value={data?.accountNumber} name="accountNumber" />
                    <br />
                </div>

                <div className='col-md-6 col-sm-6 col-12 mt-2'>
                    <label className='form-label'>IFSC code :</label>
                    <input className='form-control' type="text" disabled value={data?.ifscCode} name="ifscCode" />
                    <br />
                </div>

                <div className='col-md-6 col-sm-6 col-12 mt-2'>
                    <label className='form-label'>Id number :</label>
                    <input className='form-control' type="text" disabled value={data?.idNumber} name="idNumber" />
                    <br />
                </div>
            </div>:<>
            <div>No account details added yet.</div>
            </>}
        </>
    )
}

